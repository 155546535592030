
import Calculator from "./Calculator"
import Tabs from "./Tabs"
import Tab from "./Tab"
import ICountUp from 'vue-countup-v2';

export default {
  name: 'Network',
  components: {
    Calculator,
    Tabs,
    Tab,
    ICountUp
  },
  data() {
    return {
      networks: [
        {
          "network_name": "Elrond",
          "available": "yes",
          "staked_with_us": "12500",
          "rewards_paid": "2114.58",
          "active_clients": "15",
          "yearly_return": "20.74",
          "min_range": "1",
          "max_range": "10000",
          "ticker": "EGLD",
          "price_usd": "129.18",
          "price_eur": "109.07",
          "change_24h": "-2.08",
          "chain_type": "Mainnet",
          "unbonding_period": "10",
          "more_info_header": "Elrond is defined as the internet scale blockchain.",
          "more_info_details": "A highly scalable, fast and secure blockchain platform for distributed apps, enterprise use cases and the new internet economy. More info at https://elrond.com",
          "logo_svg": "Elrond network",
          "svg_url": "https://data.validblocks.com/networks/elrond-logo.svg",
          "staking_url": "https://validblocks.com/staking"
        },
        {
          "network_name": "Solana",
          "available": "yes",
          "staked_with_us": "231218",
          "rewards_paid": "4335.0",
          "active_clients": "11",
          "yearly_return": "15.0",
          "min_range": "1",
          "max_range": "50000",
          "ticker": "SOL",
          "price_usd": "13.43",
          "price_eur": "11.29",
          "change_24h": "-1.24",
          "chain_type": "Mainnet Beta",
          "unbonding_period": "2",
          "more_info_header": "Build crypto apps that scale",
          "more_info_details": "Solana is a fast, secure, and censorship resistant blockchain providing the open infrastructure required for global adoption. More info at https://solana.com/",
          "logo_svg": "Solana network",
          "svg_url": "https://data.validblocks.com/networks/solana-logo.svg",
          "staking_url": "https://new.solana.surf/validator/6hkfqeNAbURk7CmAQsP4Qm6WwHVF4LxHupEvQf7Tkrf1"
        },
        {
          "network_name": "CUDOS",
          "available": "no",
          "staked_with_us": "2000000",
          "rewards_paid": "96986.0",
          "active_clients": "1",
          "yearly_return": "TBD",
          "min_range": "1000",
          "max_range": "1000000",
          "ticker": "CUDOS",
          "price_usd": "0.055",
          "price_eur": "0.046",
          "change_24h": "17.92",
          "chain_type": "Testnet",
          "unbonding_period": "TBD",
          "more_info_header": "The Infrastructure token for Web 3.0",
          "more_info_details": "CUDOS is a decentralised compute network providing a trusted layer 2 oracle network for blockchains and scalable cloud resources. More info at https://cudos.org",
          "logo_svg": "Cudos network",
          "svg_url": "https://data.validblocks.com/networks/cudos-logo.svg",
          "staking_url": "https://cudos.org"
        },
        {
          "network_name": "Sifchain",
          "available": "no",
          "staked_with_us": "8196",
          "rewards_paid": "0.0",
          "active_clients": "1",
          "yearly_return": "TBD",
          "min_range": "100",
          "max_range": "100000",
          "ticker": "ROWAN",
          "price_usd": "1.22",
          "price_eur": "1.0",
          "change_24h": "-2.6",
          "chain_type": "Betanet",
          "unbonding_period": "TBD",
          "more_info_header": "The world’s first omni-chain decentralized exchange",
          "more_info_details": "More performant, more robust cryptoeconomics for trading and security, more flexible trading capabilities, an extensible omni-chain roadmap and eventual true DAO governance. Sifchain is the future of DeFi. More info at https://sifchain.finance",
          "logo_svg": "Sifchain finance",
          "svg_url": "https://data.validblocks.com/networks/sifchain-logo.svg",
          "staking_url": "https://wallet.keplr.app/#/sifchain/stake?modal=detail&validator=sifvaloper1fdppshyftmfxsqy9ln66qkxc8q6faktdrcmarn"
        }
      ],
      disabledButton: true,
      delay: 1000,
      options: {
          useEasing: true,
          useGrouping: true,
          separator: '.',
          decimal: '.',
          prefix: '$',
          suffix: ''
        },
      optionsDelegators: {
        prefix:''
      }
    }
  },
  computed: {
    activeNetworks() {
      let networks = this.observerToObject(this.networks);
      return networks.filter(network => network.available === 'yes')
    },
    inactiveNetworks() {
      let networks = this.observerToObject(this.networks);
      return networks.filter(network => network.available === 'no')
    },
    totalStakingAssets() {
      let networks = this.observerToObject(this.networks);
      const totalAssets = networks.reduce((accumulator, network) => {
        if (network.available === 'yes') {
          return accumulator + parseInt(network.staked_with_us);
        } else {
          return accumulator;
        }
      }, 0);
      return totalAssets;
    },
    totalRewardsPaid() {
      let networks = this.observerToObject(this.networks);
      const totalRewards = networks.reduce((accumulator, network) => {
        if (network.available === 'yes') {
          return accumulator + parseInt(network.rewards_paid);
        } else {
           return accumulator;
        }
      }, 0);

      return totalRewards;
    },
    totalActiveClients() {
      let networks = this.observerToObject(this.networks);
      const totalClients = networks.reduce((accumulator, network) => {
        if (network.available === 'yes') {
          return accumulator + parseInt(network.active_clients);
        } else {
          return accumulator;
        }
      }, 0);

      return totalClients;
    }
  },
  created() {
   this.fetchData();
  },
  methods: {
    fetchData() {
      return this.fetchNetworks().then((data)=>{
        this.networks = data;
      });
    },

    async fetchNetworks() {
      const url = 'https://validblocks.com/data/networks.csv';
      const response = await fetch(url, {
        cache: 'no-cache',
      }).then(response => {
        return response.text();
      }).catch(() => {
          return false;
      });
      if (response) {
        return await this.csvToJson(response);
      } else {
        return this.networks;
      }
    },

    csvToJson(file){
      const lines = file.trim().split("\n");
      const result = [];
      const headers = lines[0].split(";");

      for (let i=1; i<lines.length; i++) {

          let obj = {};
          var currentline = lines[i].split(";");

          for(let j=0; j<headers.length; j++) {
              obj[headers[j]] = currentline[j];
          }
          result.push(obj);
      }
      return result;
    },

    observerToObject(object) {
      object = JSON.parse(JSON.stringify(object));
      return  object.filter((item) => item);
    },
  }
}
