
export default {
  name: 'Tab',
  props: {
    name: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
    data() {
      return {
        isActive: false
      };
    },
    computed: {
      href() {
        return '#' + this.name.toLowerCase().replace(/ /g, '-');
      }
    },
    mounted() {
    if(window.location.hash) {
      let tempUrl = decodeURI(window.location.hash).split('#')[1];
      if(tempUrl.replace(/-/g, ' ') === this.name.toLowerCase()){
        this.isActive = true;
      }
    } else {
      this.isActive = this.selected;
    }
  }
}
