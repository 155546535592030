
export default {
  name: 'Tabs',
  data() {
    return {
      tabs: []
    };
  },
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    let tempUrl = decodeURI(window.location.hash).split('#')[1];
    if( tempUrl === 'contact') {
      this.tabs[0].isActive = true;
    }
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = (tab.name == selectedTab.name);
      });
    }
  }
}
