import { render, staticRenderFns } from "./Network.vue?vue&type=template&id=7f54da03&"
import script from "./Network.vue?vue&type=script&lang=js&"
export * from "./Network.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Calculator: require('/home/graffino/actions-runner/_work/ValidBlocks/ValidBlocks/components/Calculator.vue').default,Tab: require('/home/graffino/actions-runner/_work/ValidBlocks/ValidBlocks/components/Tab.vue').default,Tabs: require('/home/graffino/actions-runner/_work/ValidBlocks/ValidBlocks/components/Tabs.vue').default})
