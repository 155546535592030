
import veeno from 'veeno';
import { extend } from 'vee-validate';
import { min_value, max_value } from 'vee-validate/dist/rules';

extend('min_value', {
  ...min_value,
  message: 'Minimum amount is {min}',
});
extend('max_value', {
  ...max_value,
  message: 'Maximum amount is {max}',
});

export default {
  name: "Calculator",
  components: {
    veeno,
  },
  props: {
    logoName: { type: String, required: true },
    chainType: { type: String, required: true },
    unbondingPeriod: { type: String, required: true },
    detailsTitle: { type: String, required: true },
    detailsDescription: { type: String, required: true },
    yearlyReturn: { type: String, default: '0', required: true },
    ticker: { type: String, required: true },
    stakingUrl: { type: String, required: true },
    svgUrl: { type: String, required: true },
    networkName: { type: String, required: true },
    dailyChange: { type: String, required: true },
    priceUsd: { type: String, required: true },
    priceEur: { type: String, required: true },
    minRange: { type: String, required: true },
    maxRange: { type: String, required: true },
    disabledButton: { type: Boolean , default: false }
  },
  data() {
    return {
      isHidden: true,
      isEur: false,
      isUsd: true,
      sliderValue: null,
      sliderInputValue: null,
      isVisible: false,
      vcoConfig: {
        handler: this.handler,
        middleware: this.middleware,
        events: ['dblclick', 'click'],
      }
    }
  },
  computed: {
    currency() {
      let valute="";
      if(this.isEur === true)
      {
        valute = '€';
      } else if(this.isUsd === true){
        valute = '$';
      }
      return valute;
    },
    yearlyReturnPercentage() {
      let result = '';

      if (parseFloat(this.yearlyReturn) > 0) {
        result = '+' + this.yearlyReturn;
      }
      else {
        result= this.yearlyReturn;
      }

      return result;
    },
    stripSignDailyChange() {
      return this.dailyChange.replace('-', '');
    },
    dailyValue() {
      return (this.yearlyValue / 365).toFixed(2);
    },
    dailyCrypto() {
      return (this.yearlyCrypto / 365).toFixed(2);
    },
    monthlyValue() {
      return (this.yearlyValue / 12).toFixed(2);
    },
    monthlyCrypto() {
      return (this.yearlyCrypto / 12).toFixed(2);
    },
    yearlyValue() {
      switch (this.currency) {
        case '€':
          return (this.yearlyCrypto * this.priceEur).toFixed(2);
        case '$':
          return (this.yearlyCrypto * this.priceUsd).toFixed(2);
        default:
          return (this.yearlyCrypto * this.priceUsd).toFixed(2);
      }
    },
    yearlyCrypto() {
      const apyValue = this.yearlyReturn / 100;

      return (apyValue * this.sliderValue).toFixed(2);
    },
    minAmount() {
      return parseInt(this.minRange);
    },
    maxAmount() {
      return parseInt(this.maxRange);
    },
  },
  mounted() {
    this.formatNumber();
  },
  methods: {
    formatNumber() {
      let values = document.querySelectorAll(".noUi-value-large");
      let value = null;
      values.forEach(element => {
        value = element.dataset.value
        if(value < 999) {
          element.innerHTML = value;
        } else if(value < 1000000) {
          element.innerHTML = Math.round(value/1000) + "k"
        } else if(value < 10000000) {
          element.innerHTML = (value/1000000) + "m";
        } else if(value < 1000000000) {
          element.innerHTML = Math.round(value/1000000) + "m";
        } else if(value < 1000000000000) {
          element.innerHTML = Math.round((value/1000000000)) + "b";
        } else element.innerHTML = "1T+";
      });
    },
    onClickOutside() {
        this.isVisible = !this.isVisible;
    },
    middleware() {
      return true
    },
    toEur() {
      this.isEur = true;
      this.isUsd = false;
    },
    toUsd() {
      this.isEur = false;
      this.isUsd = true;
    },
    isCalculatorHide() {
      this.isHidden = true;
    },
    editValue() {
      this.$refs.slider.focus();
    },
    isCalculatorVisible() {
      this.isHidden = false
    },
    showDetails() {
      this.isVisible = true
    },
    hideDetails() {
      this.isVisible = false
    },
    sliderStart() {
      return this.sliderValue;
    },
    sliderSlide() {
      return this.sliderValue;
    },
    sliderChange() {
      this.sliderInputValue = parseFloat(this.sliderValue);
    },
    sliderUpdate() {
      return this.sliderValue;
    },
    updateSliderValue(event) {
      this.sliderInputValue = parseFloat(event.target.value);
    },
  }
}
